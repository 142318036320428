import http from "../axios/http"

//分机列表
export let getExtList = (params) => {
    return http.get("/ext/ext_list", { params })
}
//分机列表
export let postExtAdd = (params) => {
    return http.post("/ext/ext_add", params)
}
//分机删除
export let postExtDel = (params) => {
    return http.post("/ext/ext_del", params)
}
//分机删除
export let postExtDeit = (params) => {
    return http.post("/ext/ext_edit", params)
}
