<template>
  <div class="Extmanage page">
    <div class="top">
      <a-input
        placeholder="客户名称"
        style="width: 200px; margin-right: 20px"
      ></a-input>
      <a-button type="primary">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <div class="addzhongji">
      <div>
        <a-button type="primary" style="margin-right: 15px"> 分配 </a-button>
        <a-button type="primary" @click="addExt"> 添加 </a-button>
        <!-- <a-button type="primary"> 批量添加 </a-button> -->
      </div>
      <div>* 本机系统的IP地址：47.107.71.25 端口号：5686</div>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
      rowKey="ext_id"
      :pagination="{
        current: page,
        total: total,
      }"
      @change="pageChange"
    >
      <template #operation="{ record }">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 4px"
          @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-popconfirm
          v-if="data.length"
          title="确定删除吗?"
          @confirm="handleDelete(record.ext_id)"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :title="ext_id ? '编辑分机' : '添加分机'"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="extmanage"
      @ok="handleOk"
      @cancel="fromRest"
    >
      <a-form :model="formState" ref="formRef" :rules="rules">
        <a-form-item label="分机号码" name="name">
          <a-input
            placeholder="请输入"
            v-model:value="formState.name"
          ></a-input>
        </a-form-item>
        <a-form-item label="分机密码" name="password">
          <a-input
            placeholder="请输入"
            v-model:value="formState.password"
          ></a-input>
        </a-form-item>
        <a-form-item label="描述" name="description">
          <a-input
            placeholder="请输入"
            v-model:value="formState.description"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref } from "@vue/reactivity";
import {
  getExtList,
  postExtAdd,
  postExtDel,
  postExtDeit,
} from "../../service/extmanage.js";
import { onMounted } from "@vue/runtime-core";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "序号",
    dataIndex: "user_id",
  },
  {
    title: "分机号吗",
    dataIndex: "name",
  },
  {
    title: "分机密码",
    dataIndex: "password",
  },
  {
    title: "分机DID",
    dataIndex: "ext_id",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "Extmanage",
  components: {
    SearchOutlined,
  },
  setup() {
    const state = reactive({
      data: [],
      name: undefined,
      total: undefined,
      visible: undefined,
      page: 1,
      ext_id: undefined,
    });
    const formRef = ref();
    const formState = reactive({
      name: undefined,
      password: undefined,
      description: undefined,
    });
    const rules = {
      name: [
        {
          required: true,
          message: "只能填写数字",
          trigger: "blur",
          validator: function (rule, value) {
            let regExp = /^[0-9]{1,20}$/;
            if (regExp.test(value)) {
              return Promise.resolve("通过");
            } else {
              return Promise.reject("只能填写数字或字符");
            }
          },
        },
      ],
      password: [
        {
          required: true,
          message: "分机密码是必填项",
          trigger: "blur",
        },
      ],
    };
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getExtList({
        access_token: sessionStorage.getItem("token"),
        name: state.name,
        page: state.page,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const rowSelection = {
      onChange: (selectedRowKeys) => {
        console.log(selectedRowKeys);
        // state.selectedRowKeys = selectedRowKeys;
      },
    };
    const handleDelete = async (ext_id) => {
      console.log(ext_id);
      const res = await postExtDel({
        access_token: sessionStorage.getItem("token"),
        ext_id,
      });
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        formRef.value.resetFields();
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const addExt = () => {
      state.visible = true;
    };
    const handleOk = async () => {
      const result = await formRef.value.validate();
      console.log(result);
      if (result) {
        if (state.ext_id) {
          const res = await postExtDeit({
            access_token: sessionStorage.getItem("token"),
            ...formState,
            ext_id: state.ext_id,
          });
          console.log(res);
          if (res.data.status === 100000) {
            message.success(res.data.msg);
            formRef.value.resetFields();
            state.visible = false;
            state.ext_id = undefined;
            init();
          } else {
            message.error(res.data.msg);
          }
        } else {
          const res = await postExtAdd({
            access_token: sessionStorage.getItem("token"),
            ...formState,
          });
          console.log(res);
          if (res.data.status === 100000) {
            message.success(res.data.msg);
            formRef.value.resetFields();
            state.visible = false;
            init();
          } else {
            message.error(res.data.msg);
          }
        }
        console.log(formState);
      }
    };
    const pageChange = (e) => {
      //   console.log(e.current);
      state.page = e.current;
      init();
    };
    const fromRest = () => {
      formRef.value.resetFields();
    };
    const handleEdit = (record) => {
      //   formRef.value.resetFields();
      console.log(record);
      state.visible = true;
      formState.name = record.name;
      formState.password = record.password;
      formState.description = record.description;
      state.ext_id = record.ext_id;
    };

    return {
      rowSelection,
      columns,
      ...toRefs(state),
      handleDelete,
      formState,
      formRef,
      rules,
      addExt,
      handleOk,
      pageChange,
      fromRest,
      handleEdit,
    };
  },
};
</script>
<style>
.extmanage .ant-form-item-label {
  min-width: 100px;
}
.extmanage .ant-form {
  margin-right: 30px;
}
</style>
<style scoped>
.Extmanage {
  padding: 15px;
}
/* .top {
  border-bottom: 1px solid #e2dfdf;
  padding-bottom: 20px;
} */

.addzhongji {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
</style>